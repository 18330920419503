import './Geolocation.css';
import GlobalEmitter from '../GlobalFunctionAndVariables/EventEmitter';


GlobalEmitter.on(GlobalEmitter.ON_START, onGeo);

function onGeo(e){
}


